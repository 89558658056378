// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-page-tsx": () => import("./../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-examples-tsx": () => import("./../src/pages/contact/examples.tsx" /* webpackChunkName: "component---src-pages-contact-examples-tsx" */),
  "component---src-pages-contact-file-upload-tsx": () => import("./../src/pages/contact/file-upload.tsx" /* webpackChunkName: "component---src-pages-contact-file-upload-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-thanks-tsx": () => import("./../src/pages/contact/thanks.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */)
}

